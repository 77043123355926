<template>
  <div class="row">
    
    <div class="col-md-12">
        <b-card>
            <div class="row mb-1" v-for="setting in settings" :key="setting.key">
                <div class="col-md-2">
                    <label :for="setting.key" class="text-capitalize">{{ setting.title }}:</label>
                </div>
                <div class="col-md-10">
                    <div v-if="setting.type == 'file'">
                        <input type="file" :id="setting.key" :ref="setting.key" @change="changeFileSetting" v-if="checkPermission('update settings')">
                        <br>
                        <a v-if="setting.value" :href="setting.value" target="_blank">
                            See current file
                            <feather-icon
                                icon="ExternalLinkIcon"
                                class="mr-75"
                            />
                        </a>
                    </div>
                    <div v-else-if="setting.type == 'boolean'">
                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" type="checkbox" :id="setting.key" :name="setting.key" v-model="formPayload[setting.key]">
                            <label class="custom-control-label" :for="setting.key">
                            Yes
                            </label>
                        </div>
                    </div>
                    <div class="input-group" v-else>
                        <div class="input-group-prepend" v-if="setting.attributes.prepend != undefined">
                            <span class="input-group-text">{{ setting.attributes.prepend }}</span>
                        </div>
                        <input :type="setting.type" class="form-control" :id="setting.key" v-model="formPayload[setting.key]" :placeholder="'Enter ' + setting.title + '...'" v-bind:readonly="!checkPermission('update settings')">
                        <div class="input-group-append" v-if="setting.attributes.append != undefined">
                            <span class="input-group-text">{{ setting.attributes.append }}</span>
                        </div>
                    </div>
                    
                    <small>{{ setting.description }}</small>
                </div>
            </div>
            <div class="row mb-1" v-if="checkPermission('update settings')">
                <div class="col-md-2"></div>
                <div class="col-md-10">
                    <button class="btn btn-primary" @click="updateSetting()">Save Setting</button>
                </div>
            </div>
        </b-card>
    </div>

  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
    title () {
        return `Setting`
    },
    setup(props) {
        return {
        checkPermission,
        successNotification,
        errorNotification,
        }
    },
    data() {
        return {
            settings: [],
            formPayload: {},
        }
    },
    components: {
        BCard,
        BCardText,
    },
    mounted() {
        this.getSetting()
    },
    methods: {
        changeFileSetting(event) {
            this.formPayload[event.target.id] = event.target.files[0];
        },
        getSetting() {
            this.$http.get('/admin/settings')
            .then(response => {
                this.settings = response.data.data
                var context = this
                this.settings.forEach(function(item){
                    context.formPayload[item.key] = item.value
                })
            })
        },
        updateSetting() {
            var form = new FormData();

            // Remove payload for type = files if not update
            var context = this;
            this.settings.forEach(function(item){
                if (item.type === 'file' && typeof context.formPayload[item.key] == 'string') {
                    context.formPayload[item.key] = null;
                }
                
                if (item.type === 'boolean') {
                    context.formPayload[item.key] = context.formPayload[item.key] ? '1' : '0'
                }
            });

            for (var key in this.formPayload) {
                if (this.formPayload.hasOwnProperty(key) &&  this.formPayload[key] != null &&  this.formPayload[key] != '') {
                    form.append(key, this.formPayload[key]);
                }
            }

            form.append('_method', 'PATCH');
            this.$http.post('/admin/settings/', form, {
                headers: { 'content-type': 'multipart/form-data' }
            })
            .then(response => {

                this.getSetting()
                successNotification(this, 'Success', 'Setting updated!')

            })
            .catch(error => {
                if (error.response.data.meta.messages.length > 0) {
                    errorNotification(this, 'Oops!', error.response.data.meta.messages)          
                }
            })
        }
    }
}
</script>

<style>

</style>
